export default function AdvantagesSection() {
    return (
        <>
            <section className="section section-dark">
                <div className="container">
                    <div className="section_padding">
                        <div className="section_header">
                            <div className="section_item item--text">
                                <div className="section_title"><h1>Чому Купувати Авто <br/> з <span
                                    style={{color: 'rgb(230, 38, 38)'}}>Америки</span> Вигідно?</h1></div>
                            </div>
                        </div>
                        <div className="row section_padding_2">
                            <div className="info_text col-lg-4 col-md-12 col-sm-12"><i className="fa-solid fa-percent"
                                                                                       aria-hidden="true"></i><p>Готовий
                                автомобіль виходить дешевше на 15%-30% від ринкової ціни в Україні</p></div>
                            <div className="info_text info_padding col-lg-4 col-md-12 col-sm-12"><i
                                className="fa-solid fa-book" aria-hidden="true"></i><p>Забезпечення повного доступу до
                                прозорої історії машини</p></div>
                            <div className="info_text info_padding col-lg-4 col-md-12 col-sm-12"><i
                                className="fa-solid fa-car" aria-hidden="true"></i><p>Висока якість збірки та відмінний
                                технічний стан вживаних автомобілів</p></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}