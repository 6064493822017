import React from 'react';
import { Collapse } from 'antd';
import styled from 'styled-components';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const faqData = [
  {
    key: '1',
    question: 'Якщо авто вийде дорожче?',
    answer:
      'Ми виключаємо такі ситуації, так як закладаємо з самого початку всі додаткові платежі які можуть бути по конкретному лоту. Ми завжди робимо всі розрахунки максимально точно, та на момент отримання авто його кінцева ціна часто виходить навіть нижче очікуваної.',
  },
  {
    key: '2',
    question: 'Як я буду знати де моє авто?',
    answer:
      'Ми завжди надаємо вам інформацію де та на якому етапі ваш автомобіль. Також ви будете мати номер контейнера, завдяки якому зможете самостійно відслідковувати статус в реальному часі.',
  },
  {
    key: '3',
    question: 'Які гарантії що я отримаю своє авто?',
    answer:
      'З кожним клієнтом ми підписуємо договір, який має юридичну силу. Ви можете розірвати його, але тільки до моменту того як авто куплене на аукціоні. В даному договорі ми гарантуємо, що Ваше авто буде привезене в Україну та передане Вам.',
  },
  {
    key: '4',
    question: 'Чи потрібно мені відразу сплатити всю суму прорахунку за авто?',
    answer:
      'Ні, Ви сплачуєте всі необхідні витрати по авто поступово, в терміни які вимагає процес. Спочатку іде оплата самого авто на аукціоні. Коли авто консолідоване в контейнер - ви можете сплатити логістику, але не пізніше 14 днів до дати судозаходу в порт. Наступний платіж - це оплата митних платежів на реквізити митниці, та оплата комплексу по доставці авто в Україну.',
  },
];

const FaqSection = () => {
  return (
    <Section>
      <div className="container">
        <div className="faq-header">
          <h2>Часті Запитання</h2>
        </div>
        <StyledCollapse
          accordion
          ghost
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <MinusIcon /> : <PlusIcon />
          }
        >
          {faqData.map((item) => (
            <Panel header={item.question} key={item.key}>
              <p>{item.answer}</p>
            </Panel>
          ))}
        </StyledCollapse>
      </div>
    </Section>
  );
};

const Section = styled.section`
  background-color: #161616;
  padding: 80px 0;

  .container {
    max-width: 900px;
    margin: 0 auto;
  }

  .faq-header {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      color: #fff;
      font-size: 48px;
      font-weight: 800;
      position: relative;
      display: inline-block;
      padding-bottom: 15px;
      letter-spacing: 1.5px;
    }

    h2::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 5px;
      background-color: #e62626;
      border-radius: 2px;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-bottom: 1px solid #333;
  }

  .ant-collapse-header {
    color: #fff !important;
    padding: 25px 50px;
    font-size: 20px;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease;
    background-color: transparent;
    border-radius: 5px;
    letter-spacing: 0.5px;
  }

  .ant-collapse-header:hover {
    background-color: rgba(230, 38, 38, 0.1);
    color: #e62626;
  }

  .ant-collapse-content-box {
    color: #ddd;
    padding: 20px 50px;
    font-size: 18px;
    line-height: 1.7;
    background-color: rgba(22, 22, 22, 0.9);
    border-radius: 5px;
  }

  .ant-collapse-expand-icon {
    font-size: 28px;
    color: #fff;
    transition: transform 0.3s ease;
  }
`;

const PlusIcon = styled(PlusOutlined)`
  font-size: 28px;
`;

const MinusIcon = styled(MinusOutlined)`
  font-size: 28px;
`;

export default FaqSection;
