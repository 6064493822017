export default function RoadmapSection() {
  return (
    <section className="section section-dark">
      <div className="container">
        <div className="track">
          <h1>Як проходить весь процес?</h1>
          <img
            className="img-fluid mx-auto img_1"
            src="/images/roadmap.svg"
            alt="Процес 1"
          />
          <img
            className="img-fluid mx-auto img_2"
            src="/images/roadmap_mob.svg"
            alt="Процес 2"
          />
        </div>
      </div>
    </section>
  );
}