import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Table, Button } from "antd";

const BASE_URL = process.env.REACT_APP_BACKEND_URL + '/cabinet/cars';

const STEEPS = {
    1: "Машина куплена",
    2: "Готується до відправки океаном",
    3: "Транспортується через океан",
    4: "Прямує в Україну",
    5: "Передана клієнту"
}

const COLUMNS = [
    {
        title: 'VIN',
        dataIndex: 'vin',
        key: 'vin'
    },
    {
        title: 'Етап',
        dataIndex: 'steep',
        key: 'steep'
    },
    {
        dataIndex: 'vin',
        key: 'link',
        render: (vin) => <Link to={`/cabinet/${vin}`}>Переглянути</Link>
    }
]

export default function Cabinet() {
    const [cars, setCars] = useState([]);
    const navigate = useNavigate();

    // Преобразование данных для таблицы
    const data = cars.map(car => ({
        key: car.vincode,
        vin: car.vincode,
        steep: STEEPS[car.status]
    }));

    useEffect(() => {
        const accessCode = localStorage.getItem('accessCode');
        if (!accessCode) {
            navigate('/');
            return;
        }
        axios.get(BASE_URL, {
            headers: {
                'X-Entry-Code': accessCode,
            },
        })
            .then(response => {
                setCars(response.data.data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [navigate]);

    // Функция для выхода
    const handleLogout = () => {
        localStorage.removeItem('accessCode'); // Удаляем accessCode из localStorage
        navigate('/'); // Перенаправляем на главную страницу
    };

    return (
        <main className="cabinet-page">
            <div className="container">
                <h1 className="text-center cabinet_h1">Особистий кабінет</h1>
                <Button type="primary" danger onClick={handleLogout} style={{ marginBottom: 20 }}>
                    Выйти
                </Button>
                <Table columns={COLUMNS} dataSource={data} />
            </div>
        </main>
    );
}
