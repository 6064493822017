import React, { useRef, useState } from 'react';
import { Carousel, Modal } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const cases = [{
    image: 'images/cases/car2.jpg', title: 'Ford Ecosport Titanium', year: '2020', price: '$12000', video: 'https://youtu.be/JjuHLy5R7yU'
}, {
    image: 'images/cases/car8.jpg', title: 'Hyundai Tucson', year: '2022', price: '$25000',
}, {
    image: 'images/cases/car6.jpg', title: 'Jeep Compass Latitude', year: '2019', price: '$14000', video: 'https://youtu.be/EdnH0Nt7fFQ'
}, {
    image: 'images/cases/car3.jpg', title: 'Jeep Compass Latitude', year: '2020', price: '$13000',
}, {
    image: 'images/cases/car5.jpg', title: 'Ford Escape SEL', year: '2018', price: '$11500', video: 'https://youtu.be/tty8tLZ87No'
},{
    image: 'images/cases/car4.jpg', title: 'Hyundai Tucson', year: '2018', price: '$14500',
}, {
    image: 'images/cases/car1.jpg', title: 'Ford Fusion Energi Titanium', year: '2016', price: '$14000',
}, {
    image: 'images/cases/car7.jpg', title: 'Chevrolet Camaro', year: '2020', price: '$35000',
}, {
    image: 'images/cases/car9.jpg', title: 'Mercedes-Benz GLC 43 AMG', year: '2020', price: '$47000',
}, ];

const CaseSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // screens less than 1200px
        settings: {
          slidesToShow: 2, // show 2 slides
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // screens less than 768px
        settings: {
          slidesToShow: 1, // show 1 slide
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slider = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);

  const next = () => {
    slider.current.next();
  };

  const prev = () => {
    slider.current.prev();
  };

  const handleOpenModal = (car) => {
    setSelectedCar(car);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCar(null);
  };

  const getYouTubeEmbedUrl = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]{11})(?:\S+)?/;
    const match = url.match(regex);
    if (match && match[1]) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
    return null;
  };

  return (
    <section className="section">
      <div className="container carousel_padding">
        <div className="carousel_logo d-flex">
          <h3 className="mb-5">
            <span style={{ color: 'rgb(230, 38, 38)' }}>Наші</span> кейси
          </h3>
        </div>

        <div className="px-5 position-relative">
          <RightBtn onClick={next} />
          <LeftBtn onClick={prev} />
          <Carousel {...settings} ref={slider}>
            {cases.map((car, index) => (
              <div key={index}>
                <div className="row justify-content-center">
                  <div className="width_car_button text-center">
                    <button
                      className="car_button mb-2"
                      onClick={() => handleOpenModal(car)}
                    >
                      <img
                        src={car.image}
                        alt={car.title}
                        className="d-block w-100 cases-car-img"
                      />
                    </button>
                    <h5>
                      {car.title},{' '}
                      <span style={{ color: 'rgba(22, 22, 22, 0.6)' }}>
                        {car.year}
                      </span>
                    </h5>

                    <p>
                      Ціна під ключ в Україні:{' '}
                      <span style={{ fontWeight: 600 }}>{car.price}</span>
                    </p>
                                          {car.video && (
                        <i className={'fa-brands fa-youtube icon-overlay'} />
                      )}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      {isModalOpen && selectedCar && (
        <StyledModal
          open={isModalOpen}
          onCancel={handleCloseModal}
          footer={null}
          centered
          width={800}
        >
          {selectedCar.video ? (
            <iframe
              width="100%"
              height="400px"
              src={getYouTubeEmbedUrl(selectedCar.video)}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <img
              src={selectedCar.image}
              alt={selectedCar.title}
              style={{ width: '100%', height: '400px', objectFit: 'cover' }}
            />
          )}
          <ModalContent>
            <h2>{selectedCar.title}</h2>
            <p>
              Рік випуску: <strong>{selectedCar.year}</strong>
            </p>
            <p>
              Ціна під ключ в Україні:{' '}
              <strong>{selectedCar.price}</strong>
            </p>
            {/* Add more car details here if available */}
          </ModalContent>
        </StyledModal>
      )}
    </section>
  );
};

const RightBtn = styled(RightOutlined)`
  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -25px;
  font-size: 30px;
  cursor: pointer;
  margin: 10px;

  &:hover {
    color: #7c878e;
  }
`;

const LeftBtn = styled(LeftOutlined)`
  z-index: 100;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -25px;
  font-size: 30px;
  cursor: pointer;
  margin: 10px;

  &:hover {
    color: #7c878e;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
  }
  .ant-modal-close-x {
    color: #000;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  text-align: center;

  h2 {
    margin-bottom: 15px;
    font-weight: bold;
  }

  p {
    margin-bottom: 10px;
    font-size: 16px;
  }
`;

export default CaseSlider;