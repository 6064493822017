import {Button, Card, Descriptions, Carousel, Image} from "antd";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";

const STEEPS = {
    1: "Машина куплена",
    2: "Готується до відправки океаном",
    3: "Транспортується через океан",
    4: "Прямує в Україну",
    5: "Передана клієнту",
};

export default function CarInfo() {
    const [car, setCar] = useState({});

    const {vin} = useParams();

    const url = process.env.REACT_APP_BACKEND_URL + `/cabinet/cars/${vin}`;

    useEffect(() => {
        axios
            .get(url, {
                headers: {
                    "X-Entry-Code": localStorage.getItem("accessCode"),
                },
            })
            .then((response) => {
                setCar(response.data.data);
            });
    }, [url]);


    return (
        <div className="container">
            <Card title={`Деталі автомобіля - VIN: ${car.vincode}`}>
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="Статус покупки">
                        {STEEPS[car.status]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Вінкод">{car.vincode}</Descriptions.Item>
                    <Descriptions.Item label="Дата купівлі">{car.bought_at}</Descriptions.Item>
                    <Descriptions.Item label="Марка, модель, рік">
                        {car.model_description}
                    </Descriptions.Item>
                    <Descriptions.Item label="Наявність ключів">
                        {car.has_key ? "Так" : "Ні"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Букінг від судохідної лінії">
                        {car.booking_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="Орієнтовна дата судозаходу в порт">
                        {car.booking_date}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            {car.photos_usa_warehouse && car.photos_usa_warehouse.length > 0 && (
                <div style={{marginTop: 20}}>
                    <Card title="Фото зі складу США" bordered={false}>
                        <Image.PreviewGroup>
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '16px'}}>
                                {car.photos_usa_warehouse.map((photo, index) => (
                                    <Image
                                        key={index}
                                        src={photo}
                                        alt={`Фото ${index + 1}`}
                                        style={{width: '200px', height: 'auto'}}
                                    />
                                ))}
                            </div>
                        </Image.PreviewGroup>
                    </Card>
                </div>
            )}

            {car.photos_container && car.photos_container.length > 0 && (
                <div style={{marginTop: 20}}>
                    <Card title="Фото з відкриття контейнера" bordered={false}>
                        <Image.PreviewGroup>
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '16px'}}>
                                {car.photos_container.map((photo, index) => (
                                    <Image
                                        key={index}
                                        src={photo}
                                        alt={`Фото ${index + 1}`}
                                        style={{width: '200px', height: 'auto'}}
                                    />
                                ))}
                            </div>
                        </Image.PreviewGroup>
                    </Card>
                </div>
            )}
        </div>
    );
}
