import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Modal, Input, Button, message} from 'antd';
import {UserOutlined, LoginOutlined, PhoneOutlined} from '@ant-design/icons';
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL + '/cabinet';


const Header = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [accessCodeInput, setAccessCodeInput] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const accessCode = localStorage.getItem('accessCode');
        if (accessCode) {
            setLoggedIn(true);
        }
    }, []);

    const handleLoginClick = () => {
        setShowLoginForm(true);
    };

    const handleLoginSubmit = async () => {
        try {
            const response = await axios.get(
                BASE_URL,
                {
                    headers: {
                        'X-Entry-Code': accessCodeInput,
                    },
                }
            );

            if (response.status === 200) {
                localStorage.setItem('accessCode', accessCodeInput);
                setLoggedIn(true);
                setShowLoginForm(false);
                message.success('Успешный вход!');
            }
        } catch (error) {
            if (error.response && error.response.status !== 200) {
                message.error('Неверный код доступа');
            } else {
                console.error('Error:', error);
                message.error('Произошла ошибка. Попробуйте снова.');
            }
        }
    };

    const handleProfileClick = () => {
        if (localStorage.getItem('accessCode')) {
            navigate('/cabinet');
        } else {
            message.error('Вы не авторизованы');
            setLoggedIn(false);
        }
    };

    return (
        <header className="d-block" style={{backgroundColor: '#161616'}}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light navbar-dark">
                    <div className="container-fluid">
                        {!loggedIn ? (
                                    <a
                                        className="logo_header_a_info logo_header_a logo_header_buttons"
                                        href="#"
                                        onClick={handleLoginClick}
                                    >
                                        <LoginOutlined/>
                                    </a>
                                ) : (
                                    <a
                                        className="logo_header_a_info logo_header_buttons"
                                        href="#"
                                        onClick={handleProfileClick}
                                    >
                                        <UserOutlined/>
                                    </a>
                                )}
                        <Link to="/">
                            <img height="50px" className="logo_img" src="/images/logo.png" alt="logo"/>
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 top-menu">
                                <hr className="hr_none"/>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/calculator">
                                        Калькулятор
                                    </Link>
                                </li>
                                <hr className="hr_none"/>
                            </ul>
                            <div className="logo_header">
                                <div className="logo_header_2">
                                    <a className="logo_header_a tel_1" href="tel:+380930008872">
                                        <PhoneOutlined/> +38 093 000 8872
                                    </a>
                                    <a
                                        className="logo_header_a"
                                        href="https://www.instagram.com/gromauto.ua/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa-brands fa-instagram"> </i>
                                    </a>
                                    <a
                                        className="logo_header_a"
                                        href="https://www.tiktok.com/@grom_auto.ua"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa-brands fa-tiktok"> </i>
                                    </a>
                                    <a className="logo_header_a" href="#">
                                        <i className="fa-brands fa-viber"> </i>
                                    </a>
                                    <a className="logo_header_a" href="#">
                                        <i className="fa-solid fa-paper-plane"> </i>
                                    </a>
                                    <a className="logo_header_a" href="#">
                                        <i className="fa-brands fa-facebook-f"> </i>
                                    </a>
                                </div>
                            </div>
                            <div className="logo_header_3">
                                <Link
                                    className="logo_header_a_info logo_header_a logo_header_a_3 scroll-lead-form"
                                    to="#"
                                >
                                    Зв’язатись з нами
                                </Link>
                                {!loggedIn ? (
                                    <a
                                        className="logo_header_a_info logo_header_a logo_header_a_2"
                                        href="#"
                                        onClick={handleLoginClick}
                                    >
                                        <LoginOutlined/>
                                    </a>
                                ) : (
                                    <a
                                        className="logo_header_a_info logo_header_a logo_header_a_2"
                                        href="#"
                                        onClick={handleProfileClick}
                                    >
                                        <UserOutlined/>
                                    </a>
                                )}
                                <a className="logo_header_a tel_2" href="tel:+380930008872">
                                    <PhoneOutlined/> +38 093 000 8872
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            {/* Login Form Modal */}
            <Modal
                title="Вход"
                visible={showLoginForm}
                onCancel={() => setShowLoginForm(false)}
                onOk={handleLoginSubmit}
                okText="Войти"
                cancelText="Отмена"
            >
                <Input.Password
                    placeholder="Введите код доступа"
                    value={accessCodeInput}
                    onChange={(e) => setAccessCodeInput(e.target.value)}
                />
            </Modal>
        </header>
    );
};

export default Header;
