import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import styled from "styled-components";

const LeadFormSection = () => {
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            // Пример отправки данных на бэкенд
            const response = await axios.post('/api/lead', values);
            if (response.status === 200) {
                message.success('Ваше сообщение успешно отправлено!');
            } else {
                message.error('Ошибка при отправке сообщения.');
            }
        } catch (error) {
            message.error('Произошла ошибка при отправке формы.');
        }
        setLoading(false);
    };

    return (
        <section className="section section-red" id="lead-form-section">
            <div className="container">
                <div className="contact_2">
                    <div className="contact_text">
                        <h1>Зв’яжіться з нами, щоб отримати персональний підбір машини</h1>
                    </div>
                    <Form
                        id="lead-form"
                        className="ant-form ant-form-horizontal"
                        onFinish={onFinish}
                    >
                        <div className="row">
                            <div className="contact_item col-lg-4 col-md-12 col-sm-12">
                                <p>Ім’я</p>
                                <div className="col-lg-6 w-100 h-100">
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Будь ласка, введіть ваше ім’я!',
                                            },
                                        ]}
                                    >
                                        <StyledInput
                                            id="lead-form-name"
                                            placeholder="Ім’я"
                                            className="ant-input"
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="contact_item col-lg-4 col-md-12 col-sm-12">
                                <p>Номер телефону</p>
                                <div className="col-lg-6 w-100 h-100">
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Будь ласка, введіть номер телефону!',
                                            },
                                        ]}
                                    >
                                        <StyledInput
                                            id="lead-form-phone"
                                            placeholder="+380 99 999 9999"
                                            className="ant-input"
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="contact_item col-lg-4 col-md-12 col-sm-12">
                                <div>
                                    <p style={{ color: 'rgb(230, 38, 38)' }}>.</p>
                                    <StyledButton
                                        type="primary"
                                        htmlType="submit"
                                        className="ant-btn w-100 h-100"
                                        loading={loading}
                                    >
                                        Отримати підбір авто
                                    </StyledButton>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </section>
    );
};

export default LeadFormSection;


const StyledInput = styled(Input)`
    background-color: red; !important;
    color: white;
    padding: 20px 38px;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    
    
    &:hover, &:active, &:focus{
    background-color: red;
    }
    
    &::placeholder {
        color: white;
    }
    `;

const StyledButton = styled(Button)`
    background-color: black;
    color: white;
    padding: 20px 38px;
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;


    &:hover, &:active, &:focus {
        background-color: black;
        color: white;

    }
`;